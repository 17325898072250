import React from "react";
import { Container, Form } from 'react-bootstrap';
import Navigation from './Navigation';
import Header from './Header';
import HeaderMob from "./HeaderMob";
import Footer from "./Footer";
import b1 from "../images/breakfast1.jpg";
import b2 from "../images/breakfast2.jpg";
import b3 from "../images/breakfast3.jpg";
import b4 from "../images/breakfast4.jpg";
import b5 from "../images/breakfast5.jpg";
import "./custom.css";

const Breakfast = () => {

    return (
        <Container fluid>
            <Header />
            <Navigation activePath="/menu" />
            <HeaderMob />

            <div className="main">
                <div className="meal">
                    <h1>BREAKFAST</h1>
                    <div className="row justify-content-center">
                        <div className="col-sm-6 col-md-4 col-lg-3">
                            <div className="image-group">
                                <img src={b1} alt="Milk Rice" />
                                <h5>Milk Rice</h5>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3">
                            <div className="image-group">
                                <img src={b2} alt="Dosa" />
                                <h5>Dosa</h5>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3">
                            <div className="image-group">
                                <img src={b3} alt="String Hoppers" />
                                <h5>String Hoppers</h5>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3">
                            <div className="image-group">
                                <img src={b4} alt="Hoppers" />
                                <h5>Hoppers</h5>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3">
                            <div className="image-group">
                                <img src={b5} alt="Rice & Curry" />
                                <h5>Rice & Curry</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
      </Container>
    );
}

export default Breakfast;