import React from "react";
import "./custom.css";
import "./header.css";

function Header(props) {
    const phoneNumber = '+61-449-993-531';

    // Function to initiate a phone call when clicking the phone number
    const handlePhoneClick = () => {
        window.location.href = `tel:${phoneNumber}`;
    };

    return (
        <>
            <div className="custom-bg-gold custom-text-green py-2 header-laptop">
                <div className="container">
                    <div className="d-flex justify-content-center align-items-center">
                        <div className="contact-info d-flex ">
                            <div className="address mr-3">
                                <a href="https://www.google.com/maps/search/?api=1&query=22+Grevillia Street+Oak Park"
                                    target="_blank" rel="noopener noreferrer" className="link-as-text" >
                                    22 Grevillia Street Oak Park
                                </a>
                            </div>
                            <div className="phone mr-3">
                                <span onClick={handlePhoneClick}>{phoneNumber}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </>
        
    );
}

export default Header;