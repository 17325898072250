import logo from './logo.svg';
import './App.css';
import './components/custom.css'
import Home from './components/Home';
import HoursAndLocations from './components/HoursAndLocations';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Breakfast from './components/Breakfast';
import Contact from './components/Contact';
import About from './components/About';
import Lunch from './components/Lunch';
import Dinner from './components/Dinner';
import Bakery from './components/Bakery';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path='/hours_locations' element={<HoursAndLocations />} />
          <Route path='/breakfast' element={<Breakfast />} />
          <Route path='/lunch' element={<Lunch />} />
          <Route path='/dinner' element={<Dinner />} />
          <Route path='/bakery' element={<Bakery />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/about' element={<About />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
