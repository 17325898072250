import React from "react";
import { Container, Form } from 'react-bootstrap';
import Navigation from './Navigation';
import Header from './Header';
import HeaderMob from "./HeaderMob";
import Footer from "./Footer";
import "./custom.css";
import "./hoursandlocations.css";

const HoursAndLocations = () => {
    const phoneNumber = '+61-449-993-531';

    // Function to initiate a phone call when clicking the phone number
    const handlePhoneClick = () => {
        window.location.href = `tel:${phoneNumber}`;
    }

    return (
        <Container fluid>
            <Header />
            <Navigation activePath="/hours_locations" />
            <HeaderMob />

            <div className="main">
                <div className="center">
                    <h1>HOURS & LOCATIONS</h1>
                    <div className="location">
                        <div className="address mr-3 mb-2">
                            <a href="https://www.google.com/maps/search/?api=1&query=22+Grevillia Street+Oak Park"
                                target="_blank" rel="noopener noreferrer" className="link-as-text" >
                                22 Grevillia Street Oak Park
                            </a>
                        </div>
                        <div className="phone mr-3">
                            <span onClick={handlePhoneClick}>{phoneNumber}</span>
                        </div>
                    </div>
                    <h5>Breakfast</h5>
                    <p>6:30 AM to 11:00 AM, weekdays</p>
                    <p>6:30 AM to 11:30 AM, weekends</p>
                    <h5>Lunch</h5>
                    <p>12:00 AM to 2:00 PM, daily</p>
                    <h5>Dinner</h5>
                    <p>5:30 PM to 11:00 PM, daily</p>
                    <h5>Bakery</h5>
                    <p>7:00 AM to 7:00 AM, weekdays</p>
                    <p>7:00 AM to 2:00 AM, weekends</p>
                </div>
            </div>

            <Footer />
      </Container>
    );
}

export default HoursAndLocations;