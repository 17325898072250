import React from "react";
import { Container, Form } from 'react-bootstrap';
import Navigation from './Navigation';
import Header from './Header';
import HeaderMob from "./HeaderMob";
import Footer from "./Footer";
import d1 from "../images/dinner1.jpg";
import d2 from "../images/dinner2.jpg";
import d3 from "../images/dinner3.jpg";
import d4 from "../images/dinner4.jpg";
import "./custom.css";

const Dinner = () => {

    return (
        <Container fluid>
            <Header />
            <Navigation activePath="/menu" />
            <HeaderMob />

            <div className="main">
            <div className="meal">
                    <h1>DINNER</h1>
                    <div className="row justify-content-center">
                        <div className="col-sm-6 col-md-4 col-lg-3">
                            <div className="image-group">
                                <img src={d1} alt="Pasta" />
                                <h5>Pasta</h5>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3">
                            <div className="image-group">
                                <img src={d2} alt="Spaggetti" />
                                <h5>Spaggetti</h5>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3">
                            <div className="image-group">
                                <img src={d3} alt="Parata" />
                                <h5>Parata</h5>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3">
                            <div className="image-group">
                                <img src={d4} alt="Noodles" />
                                <h5>Noodles</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
      </Container>
    );
}

export default Dinner;