import React from "react";
import { Container, Form } from 'react-bootstrap';
import Navigation from './Navigation';
import Header from './Header';
import HeaderMob from "./HeaderMob";
import Footer from "./Footer";
import bakery1 from "../images/CarouselBakery1.jpg";
import bakery2 from "../images/CarouselBakery2.jpg";
import bakery3 from "../images/CarouselBakery3.jpg";
import bakery4 from "../images/CarouselBakery4.jpg";
import bakery from "../images/Bakery.jpg";
import ImageCarousel from "./ImageCarousel";
import "./custom.css";

const Bakery = () => {
    const CarouselImg = [bakery1, bakery2, bakery3, bakery4];

    return (
        <Container fluid>
            <Header />
            <Navigation activePath="/bakery" />
            <HeaderMob />

            <ImageCarousel images={CarouselImg} />

            <div className="main">
                <div className="div-half">
                    <h2 className="h2-center">HANDCRAFTED WITH LOVE</h2>
                    <p>Our bakers are artisans, passionate about their craft. They knead, shape, and bake with love, 
                        infusing each creation with a touch of their personality. Every pastry, every loaf, 
                        is a unique masterpiece.</p>
                </div>

                <div className="row descrip des des1">
                    <div className="col-sm-12 col-md-9 order-md-2">
                        <img src={bakery} />
                    </div>
                    <div className="col-sm-12 col-md-3 order-md-1">
                        <h1><span className="h1-title">EAT PASTRY!</span></h1>
                        <p className="p-des">
                            Freshly baked croissants, muffins, and artisanal delights. Dine in or take on the go.
                            <div className="line mx-auto"></div>
                        </p>
                    </div>
                </div>
                
            </div>

            <Footer />
      </Container>
    );
}

export default Bakery;