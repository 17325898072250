import React from "react";
import { Container, Form } from 'react-bootstrap';
import Navigation from './Navigation';
import Header from './Header';
import HeaderMob from "./HeaderMob";
import Footer from "./Footer";
import l1 from "../images/lunch1.jpg";
import l2 from "../images/lunch2.jpg";
import l3 from "../images/lunch3.jpg";
import l4 from "../images/lunch4.jpg";
import "./custom.css";

const Lunch = () => {

    return (
        <Container fluid>
            <Header />
            <Navigation activePath="/menu" />
            <HeaderMob />

            <div className="main">
                <div className="meal">
                    <h1>LUNCH</h1>
                    <div className="row justify-content-center">
                        <div className="col-sm-6 col-md-4 col-lg-3">
                            <div className="image-group">
                                <img src={l1} alt="Rice & Curry" />
                                <h5>Rice & Curry</h5>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3">
                            <div className="image-group">
                                <img src={l2} alt="Fried Rice" />
                                <h5>Fried Rice</h5>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3">
                            <div className="image-group">
                                <img src={l3} alt="Biriyani" />
                                <h5>Biriyani</h5>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3">
                            <div className="image-group">
                                <img src={l4} alt="Koththu" />
                                <h5>Koththu</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
      </Container>
    );
}

export default Lunch;