import React from "react";
import { Container, Form } from 'react-bootstrap';
import Navigation from './Navigation';
import Header from './Header';
import HeaderMob from "./HeaderMob";
import Footer from "./Footer";
import ImageCarousel from "./ImageCarousel";
import inside1 from "../images/Inside1.jpg";
import inside2 from "../images/Inside2.jpg";
import inside3 from "../images/Inside3.png";
import generalManager from "../images/General Manager.jpg";
import chef from "../images/Chef.jpg";
import restManager from "../images/Restaurant Manager.jpg";
import "./custom.css";
import "./about.css";

const About = () => {
    const CarouselImg = [inside1, inside2, inside3];

    return (
        <Container fluid>
            <Header />
            <Navigation activePath="/about" />
            <HeaderMob />

            <ImageCarousel images={CarouselImg} />

            <div className="main">
                <div className="about">
                    <div className="about-descrip">
                        <h1>WHERE SPICE IS KING</h1>
                        <p>Welcome to Spicy Kitchen, where spice reigns supreme. Our culinary artisans are masters of the 
                            spice palette, creating dishes that awaken your taste buds. We take you on a global spice trail, 
                            sourcing the finest ingredients for an unforgettable dining experience.</p>
                    </div>
                    <h2 className="about-h2">OUR TEAM</h2>
                    <div className="row justify-content-center">
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <div className="image-group">
                                <img src={generalManager} alt="General Manager" />
                                <h5>MARY LIZA</h5>
                                <p>General Manager</p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <div className="image-group">
                                <img src={chef} alt="Executive Chef" />
                                <h5>JOHN CLARK</h5>
                                <p>Executive Chef</p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <div className="image-group">
                                <img src={restManager} alt="Restaurant Manager" />
                                <h5>DAVID WIZZ</h5>
                                <p>Restaurant Manager</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
      </Container>
    );
}

export default About;