import React, { useState } from "react";
import { Container, Form } from 'react-bootstrap';
import Header from './Header';
import Navigation from './Navigation';
import Footer from "./Footer";
import "./custom.css";
import "./contact.css";

const Contact = () => {
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        if (name === 'userName') {
            setUserName(value);
        }else if (name === 'email') {
            setEmail(value);
        }else if (name === 'phoneNumber') {
            setPhoneNumber(value);
        }else if (name === 'message') {
            setMessage(value);
        }
    }

    const handleSend = (e) => {
        e.preventDefault();

        const formData = {
            userName,
            email,
            phoneNumber,
            message
        };

        fetch('https://skrestaurantmysql.madushiranasooriyaportfolio.com/api/sendMessage.php', {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {
            console.log(data.message);
            alert("Message sent successfully");
            setUserName('');
            setEmail('');
            setPhoneNumber('');
            setMessage('');
            // Reset form or perform any necessary action
        })
        .catch(error => {
            alert(error);
            console.error('Error:', error);
        });
    };

    return (
        
        <Container fluid>    
            
            
            <Header />
            <Navigation activePath="/contact"/>
            
            <div className="main">
                <div className="contact">
                    <form className="contact-form" onSubmit={handleSend}>
                        <h1>CONTACT US</h1>
                        <div className="mb-3">
                            <label htmlFor="name" className="form-label">Name</label>
                            <input type="text" className="form-control" id="nameContact" placeholder="Your Name" 
                               name="userName" value={userName} onChange={handleInputChange} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">Email</label>
                            <input type="email" className="form-control" id="emailContact" placeholder="name@example.com"
                                name="email" value={email} onChange={handleInputChange} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="phoneNum" className="form-label">Phone Number</label>
                            <input type="tel" className="form-control" id="phoneContact" placeholder="0-123-456-789"
                                name="phoneNumber" value={phoneNumber} onChange={handleInputChange} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="message" className="form-label">Message</label>
                            <textarea className="form-control" id="messageContact" rows="3" placeholder="Your message here" 
                                name="message" value={message} onChange={handleInputChange} ></textarea>
                        </div>
                        <div className="btn-center">
                            <button type="submit" className="btn btn-send"><span className="">SEND</span></button>
                        </div>
                        
                    </form>
                </div>
            </div>
          
            <Footer activePath="/contact" />
      </Container>
    );
}

export default Contact;