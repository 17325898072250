import React from "react";
import "./custom.css"

function HeaderMob(props) {

    return (
        <>
            <div className="custom-bg-gold custom-text-green py-2 header-mobile">
                <div className="container">
                    <div className="d-flex justify-content-center align-items-center">
                        <div className="contact-info d-flex ">
                            <div className="address mr-3">
                                <a href="https://www.google.com/maps/search/?api=1&query=22+Grevillia Street+Oak Park"
                                    target="_blank" rel="noopener noreferrer" className="link-as-text" >
                                    22 Grevillia Street Oak Park
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </>
        
    );
}

export default HeaderMob;